import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { Heading1, BodyText } from '../Text/Text.style';
import { LogoStyle } from '../Logo/Logo.style';
import { TextLabelStyle } from '../VideoItem/VideoItemDescription/VideoItemDescription.style';
import { ImageMediaWrapperStyle } from '../ImageMedia/ImageMedia.style';
import { PlayEpisodeButtonStyle } from '../Link/Link.style';

export const PromoImageHeadingStyle = styled.div<{ hasImage?: boolean }>`
  position: relative;

  ${ImageMediaWrapperStyle} {
    &,
    & img {
      width: 100%;
      height: 280px;
      background-color: transparent;

      @media (min-width: ${props => props.theme.breakpoints.smMid}) {
        height: 350px;
      }
    }

    img {
      object-fit: cover;
    }
  }

  ${LogoStyle} {
    img {
      position: absolute;
      width: 61px;
      top: 10px;
      left: 16px;
      z-index: 1000;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: ${props => (props.hasImage ? '575px' : '305px')};

    &::before {
      position: relative;
      height: 100%;
    }

    ${ImageMediaWrapperStyle} {
      padding-top: 0;
      position: absolute;
      height: 100%;
      top: 0;

      img {
        height: 100%;
      }
    }

    ${Heading1} {
      margin-bottom: 16px;
    }

    ${LogoStyle} {
      display: none;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    height: ${props => (props.hasImage ? '620px' : '350px')};
    ${Heading1}, ${BodyText} {
      max-width: 519px;
    }
  }

  @media (max-height: ${props => props.theme.breakpoints.smMax}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    height: ${props => (props.hasImage ? '100vh' : '305px')};
  }

  @media (max-width: ${props => props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    min-height: 450px;

    ${LogoStyle} {
      display: none;
    }
  }
`;

export const PromoImageHeadingInnerWrapperStyle = styled.div<{ isFilmsPage?: boolean }>`
  width: 100%;
  height: 100%;
  margin-top: ${props => (props.isFilmsPage ? '-170px' : '0')};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const PromoImageHeadingContentStyle = styled.div<{ isImageValid: string | boolean }>`
  display: inline-block;
  width: 100%;
  z-index: 2;

  img {
    max-height: 60px;
    margin-bottom: 10px;
  }

  ${TextLabelStyle} {
    display: inline-flex;
    justify-content: center;
    position: static;
    width: 49px;
    height: 25px;
    margin-bottom: 5px;
    font-size: 16px;
  }

  ${BodyText} {
    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      max-width: 505px;
    }
  }

  & > ${Heading1} {
    margin-bottom: 8px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      margin-bottom: 16px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    position: absolute;
    top: ${props => (props.isImageValid ? 'auto' : '64px')};
    bottom: 8%;

    img {
      max-height: 120px;
      margin-bottom: 16px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    img {
      max-height: 130px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    width: 520px;
    top: ${props => (props.isImageValid ? 'auto' : '64px')};
    bottom: ${props => (props.isImageValid ? '10%' : 'auto')};
  }
`;

export const PromoImageHeadingButtonsContainerStyle = styled.div`
  display: flex;
  margin-top: 32px;

  a {
    margin-right: 16px;
    ${PlayEpisodeButtonStyle}
  }
`;

export const PromoImageAlphaLayer = styled.div`
  ${ImageMediaWrapperStyle} {
    ${props =>
      `mask-image:${linearGradient(
        props.theme.gradients.alphaLayerTransparent.bottomBorder.direction,
        props.theme.gradients.alphaLayerTransparent.bottomBorder.stops
      )};
          -webkit-mask-composite: source-out;`}
    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      ${props =>
        `mask-image:${linearGradient(
          props.theme.gradients.alphaLayerTransparent.leftBorder.direction,
          props.theme.gradients.alphaLayerTransparent.leftBorder.stops
        )},${linearGradient(
          props.theme.gradients.alphaLayerTransparent.bottomBorderInverse.direction,
          props.theme.gradients.alphaLayerTransparent.bottomBorderInverse.stops
        )};`}
    }
  }
`;
