import { FunctionComponent } from 'react';
import { SubratingIcons, RatingIcons } from './RatingIcons/RatingIcons';
import { RatingsStyle } from './Ratings.style';

const Ratings: FunctionComponent<IRatingGraphql> = props => {
  return (
    <RatingsStyle data-testid="ratingsWrapper">
      {props.rating && RatingIcons[props.rating.toLocaleLowerCase()]}
      {props.subRatings && props.subRatings.length > 0 && props.subRatings.map(sub => SubratingIcons[sub])}
    </RatingsStyle>
  );
};

export default Ratings;
