import React from 'react';
import useFeature from 'hooks/useFeature';
import { FEATURE_SLUG } from '../../constants';
import GradientStyle from './CustomGradient.style';

const CustomGradient: React.FC<{ slug: string }> = ({ slug }) => {
  const [colors, setColors] = React.useState<{ startColor: string; centerColor: string; endColor: string } | null>(
    null
  );
  const { variables } = useFeature<{ [slug: string]: string }>(FEATURE_SLUG.CUSTOM_GRADIENT);
  React.useEffect(() => {
    if (!variables?.[slug]) {
      setColors(null);
      return;
    }
    const [startColor, centerColor, endColor] = variables[slug].split(';');
    setColors({ startColor, centerColor, endColor });
  }, [variables, slug]);

  if (colors) {
    return <GradientStyle {...colors} />;
  }
  return null;
};

export default CustomGradient;
