import styled from 'styled-components';

export const RatingsStyle = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
