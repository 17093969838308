import React from 'react';
import { debounce } from 'throttle-debounce';
import { getSizesForPromoImage } from 'lib/helpers';
import { FIXED_COUNT } from '../constants';

export default function usePromotionDimensions(
  refContainer: React.RefObject<HTMLDivElement>
): { width: number; height: number } {
  const [dimensions, setDimensions] = React.useState<{ width: number; height: number }>({
    width: 0,
    height: 0
  });
  React.useEffect(() => {
    const handleResize = debounce(FIXED_COUNT.DEBOUNCE_TIME, () => {
      const { offsetHeight, offsetWidth } = refContainer.current || {};
      if (!offsetHeight || !offsetWidth) {
        return;
      }
      const { newWidth, newHeight } = getSizesForPromoImage(offsetWidth, offsetHeight);
      setDimensions({ width: newWidth, height: newHeight });
    });
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [refContainer]);
  return dimensions;
}
