import { v4 as uuidv4 } from 'uuid';
import SVG from 'react-inlinesvg';
import { ICONS } from '../../../constants/icons';

export const RatingIcons: IRatingsAndSubratingsIcons = {
  al: <SVG src={ICONS.RATING_AL} />,
  18: <SVG src={ICONS.RATING_18} />,
  16: <SVG src={ICONS.RATING_16} />,
  14: <SVG src={ICONS.RATING_14} />,
  12: <SVG src={ICONS.RATING_12} />,
  9: <SVG src={ICONS.RATING_9} />,
  6: <SVG src={ICONS.RATING_6} />
};

// VPA codes for video classification:
// g-Violence, a-Fear, s-Sex, h-Discrimination, d-Drugs/alcohol abuse, t-Coarse language
export const SubratingIcons: IRatingsAndSubratingsIcons = {
  g: <SVG src={ICONS.SUBRATING_G} key={uuidv4()} />,
  a: <SVG src={ICONS.SUBRATING_A} key={uuidv4()} />,
  s: <SVG src={ICONS.SUBRATING_S} key={uuidv4()} />,
  h: <SVG src={ICONS.SUBRATING_H} key={uuidv4()} />,
  d: <SVG src={ICONS.SUBRATING_D} key={uuidv4()} />,
  t: <SVG src={ICONS.SUBRATING_T} key={uuidv4()} />
};
