import { createGlobalStyle } from 'styled-components';

const GradientStyle = createGlobalStyle<{ startColor: string; centerColor: string; endColor: string }>`
  ${({ theme, startColor, centerColor, endColor }) => `
  body:after {
    background-image: linear-gradient(167deg, ${startColor} 0%, ${centerColor} 30%, ${endColor} 93%);

    @media (min-width: ${theme.breakpoints.mdMin}) {
      background-image: linear-gradient(159deg, ${startColor} 0%, ${centerColor} 28%, ${endColor} 87%);
    }

    @media (min-width: ${theme.breakpoints.lgMin}) {
      background-image: linear-gradient(145deg, ${startColor} 0%, ${centerColor} 24%, ${endColor} 74%);
    }
  }`} 
`;

export default GradientStyle;
