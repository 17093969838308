import styled from 'styled-components';
import { ImageMediaWrapperStyle } from '../ImageMedia/ImageMedia.style';
import { Heading1, BodyText } from '../Text/Text.style';
import { RatingsStyle } from '../Ratings/Ratings.style';
import {
  PromoImageHeadingButtonsContainerStyle,
  PromoImageHeadingStyle
} from '../PromoImageHeading/PromoImageHeading.style';

export const FormatHeadingPortraitImageStyle = styled.div`
  display: flex;
  width: 72px;

  ${ImageMediaWrapperStyle} {
    width: 72px;
    height: 108px;
    border-radius: 4px;

    & img {
      width: 100%;
      height: 100%;
    }

    img {
      max-height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 107px;
    align-items: flex-end;

    ${ImageMediaWrapperStyle} {
      position: relative;
      width: 107px;
      height: 160px;

      img {
        margin-bottom: 0;
      }
    }
  }
`;

export const FormatHeadingImageWithTextWrapperStyle = styled.div<{ isFormatPage?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: ${props => (props.isFormatPage ? '-150px' : 0)};
  padding: 8px 0 3px;
  z-index: 1;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: ${props => (props.isFormatPage ? '-170px' : 0)};
    flex-direction: row;
    margin-bottom: 20px;
    padding: 8px 0;
  }
`;

export const FormatHeadingTextStyle = styled.div`
  ${Heading1} {
    margin-bottom: -10px;
    margin-left: 0;
  }

  ${RatingsStyle} {
    margin: 16px 0 8px;

    svg {
      width: 24px;
      height: 24px;

      @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
        width: 32px;
        height: 32px;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      margin-left: 0;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding-left: 25px;

    ${Heading1} {
      margin-left: 0;
      margin-bottom: 5px;
    }
  }
`;

export const FormatHeadingWithoutImageStyle = styled.div`
  margin-top: 130px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 150px;
  }
`;

export const FormatHeadingDescriptionStyle = styled.div`
  margin: 5px 0;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 0;
  }
`;

export const FormatHeadingPromoImageButtonsWrapperStyle = styled(PromoImageHeadingButtonsContainerStyle)`
  margin-top: 16px;
`;

export const FormatHeadingHomePromoImageButtonsStyle = styled(PromoImageHeadingButtonsContainerStyle)`
  margin-top: 0;
`;

export const FormatHeadingPromoImageHeadingStyle = styled(PromoImageHeadingStyle)`
  background: none;
`;

export const FormatHeadingImageWithTextWrapperStyleHome = styled(FormatHeadingImageWithTextWrapperStyle)`
  padding-bottom: 16px;
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 0;
    padding: 8px 0 0;
  }
`;

export const FormatHeadingTextStyleHome = styled(FormatHeadingTextStyle)`
  padding-left: 0;

  ${Heading1} {
    margin-bottom: 0;
    margin-left: 0;
  }

  ${BodyText} {
    opacity: 0.7;
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 12px;
    }
  }
`;

export const FormatHeadingSubtitleStyle = styled(BodyText)`
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  overflow: hidden;
  overflow-wrap: break-word;
`;

export const FormatHeadingDateTimeStyleHome = styled.div`
  opacity: 0.7;

  ${BodyText} {
    font-size: 11px;
    font-weight: bold;
    line-height: 2;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      font-size: 12px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin: 4px 0 16px;
  }
`;
