import { useRef } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { useScreenDimensions } from 'lib/useScreenDimensions';
import VideoModel from 'models/video.model';
import SeriesModel from 'models/series.model';
import { toggleHeaderState } from 'queries/headerState/headerStateActions';
import usePromotionDimensions from 'hooks/usePromotionDimensions';
import {
  PromoImageHeadingStyle,
  PromoImageHeadingInnerWrapperStyle,
  PromoImageHeadingContentStyle,
  PromoImageAlphaLayer
} from '../PromoImageHeading/PromoImageHeading.style';
import {
  FormatHeadingPortraitImageStyle,
  FormatHeadingImageWithTextWrapperStyle,
  FormatHeadingImageWithTextWrapperStyleHome,
  FormatHeadingTextStyle,
  FormatHeadingTextStyleHome,
  FormatHeadingWithoutImageStyle,
  FormatHeadingDescriptionStyle,
  FormatHeadingPromoImageButtonsWrapperStyle,
  FormatHeadingHomePromoImageButtonsStyle,
  FormatHeadingPromoImageHeadingStyle,
  FormatHeadingDateTimeStyleHome,
  FormatHeadingSubtitleStyle
} from './FormatHeading.style';
import { BodyText, Heading1 } from '../Text/Text.style';
import { Grid, GridElement } from '../../styles/grid';
import {
  IMAGE_MEDIA_TYPE,
  CLIENT,
  DEFAULT_IMAGES,
  ICONS,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_NAME,
  TEALIUM_EVENT_LABEL,
  PROGRAM,
  BREAKPOINTS
} from '../../constants';
import Link from '../Link';
import ImageMedia from '../ImageMedia';
import Ratings from '../Ratings/Ratings';
import Bookmark from '../Bookmark';

const FormatHeading: React.FC<IFormatHeading & (VideoModel | (SeriesModel & { ctaLabel?: string; path?: string }))> = ({
  imageLandscape,
  imagePortrait,
  guid,
  path,
  ratings,
  shortDescription,
  type,
  title,
  subtitle,
  viewMode,
  videoDateTime,
  seriesId,
  metadata,
  videoMetadata,
  ctaLabel,
  isFormatPage = true
}) => {
  const IsCoverImageValid = !imageLandscape?.src.includes(DEFAULT_IMAGES.LANDSCAPE);
  const promoImageContainer = useRef<HTMLDivElement>(null);
  const { width, height } = usePromotionDimensions(promoImageContainer);
  const [screenWidth] = useScreenDimensions();
  const isMobileScreenWidth = screenWidth < BREAKPOINTS.mdMin;

  const seriesGuid = isFormatPage ? guid : seriesId?.split('/').pop();
  return (
    <ApolloConsumer>
      {client => {
        if (isFormatPage && !IsCoverImageValid) {
          toggleHeaderState(client as any, { isTransparent: false });
        }
        return (
          <>
            {isFormatPage ? (
              <PromoImageHeadingStyle
                data-testid="formatPromoImage"
                hasImage={IsCoverImageValid}
                ref={promoImageContainer}
              >
                {!!(height && IsCoverImageValid) && (
                  <PromoImageAlphaLayer>
                    <ImageMedia
                      publicId={IsCoverImageValid && imageLandscape.src}
                      imageMedia={{ type: imageLandscape.type || IMAGE_MEDIA_TYPE.REMOTE }}
                      shouldLazyLoad={false}
                      transformations={
                        viewMode === CLIENT.DESKTOP
                          ? {
                              width,
                              height,
                              crop: 'fill',
                              gravity: 'faces:auto',
                              fetch_format: 'auto',
                              aspect_ratio: '16:9'
                            }
                          : {
                              width: '1000',
                              crop: 'fill',
                              gravity: 'faces:auto',
                              fetch_format: 'auto',
                              aspect_ratio: '4:3'
                            }
                      }
                    />
                  </PromoImageAlphaLayer>
                )}
                <PromoImageHeadingInnerWrapperStyle>
                  <Grid>
                    <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
                      <PromoImageHeadingContentStyle data-testid="promoHeadingContent" isImageValid={IsCoverImageValid}>
                        {!IsCoverImageValid && <FormatHeadingWithoutImageStyle />}
                        <FormatHeadingImageWithTextWrapperStyle isFormatPage>
                          <FormatHeadingPortraitImageStyle data-testid="promoPortraitImage">
                            {!isMobileScreenWidth ? (
                              <ImageMedia
                                imageMedia={imagePortrait}
                                title={title}
                                transformations={{
                                  width: 'auto',
                                  height: '244',
                                  crop: 'scale',
                                  aspect_ratio: '2:3',
                                  fetch_format: 'auto'
                                }}
                              />
                            ) : null}
                          </FormatHeadingPortraitImageStyle>
                          <FormatHeadingTextStyle>
                            {ratings && ratings.length > 0 ? (
                              <Ratings rating={ratings[0].rating} subRatings={ratings[0].subRatings} />
                            ) : null}
                            <Heading1 data-testid="promoHeadingTitle">{title}</Heading1>
                            <FormatHeadingDescriptionStyle>
                              {!isMobileScreenWidth && shortDescription && (
                                <BodyText data-testid="promoDescription">{shortDescription}</BodyText>
                              )}
                            </FormatHeadingDescriptionStyle>
                          </FormatHeadingTextStyle>
                          <FormatHeadingDescriptionStyle>
                            {isMobileScreenWidth && shortDescription && (
                              <BodyText data-testid="promoDescription">{shortDescription}</BodyText>
                            )}
                          </FormatHeadingDescriptionStyle>
                        </FormatHeadingImageWithTextWrapperStyle>
                        <FormatHeadingPromoImageButtonsWrapperStyle data-testid="promoImageHeadingButtonsContainerStyle">
                          {!!path && (
                            <Link
                              path={path}
                              clickEventValues={{
                                category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
                                name: TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
                                label: TEALIUM_EVENT_LABEL.HEADER_PROMO,
                                defaultValues: videoMetadata
                              }}
                            >
                              <img src={ICONS.PLAY_FILL} alt="Play button" />
                              {ctaLabel}
                            </Link>
                          )}
                          {seriesGuid && (
                            <Bookmark
                              programGuid={seriesGuid}
                              clickEventValues={{
                                category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
                                name: '',
                                label: TEALIUM_EVENT_LABEL.HEADER_PROMO,
                                defaultValues: metadata
                              }}
                            />
                          )}
                        </FormatHeadingPromoImageButtonsWrapperStyle>
                      </PromoImageHeadingContentStyle>
                    </GridElement>
                  </Grid>
                </PromoImageHeadingInnerWrapperStyle>
              </PromoImageHeadingStyle>
            ) : (
              <FormatHeadingPromoImageHeadingStyle data-testid="promoImage" ref={promoImageContainer}>
                <Grid>
                  <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
                    <PromoImageHeadingContentStyle data-testid="promoHeadingContent" isImageValid={IsCoverImageValid}>
                      {!IsCoverImageValid && <FormatHeadingWithoutImageStyle />}
                      <FormatHeadingImageWithTextWrapperStyleHome>
                        <FormatHeadingTextStyleHome>
                          <Heading1 data-testid="promoHeadingTitle">{title}</Heading1>
                          {type === PROGRAM.CLIP && <FormatHeadingSubtitleStyle>{subtitle}</FormatHeadingSubtitleStyle>}
                          <FormatHeadingDateTimeStyleHome>
                            {videoDateTime && (
                              <BodyText data-testid="promoDateTime" suppressHydrationWarning>
                                {videoDateTime}
                              </BodyText>
                            )}
                          </FormatHeadingDateTimeStyleHome>
                        </FormatHeadingTextStyleHome>
                      </FormatHeadingImageWithTextWrapperStyleHome>
                      <FormatHeadingHomePromoImageButtonsStyle data-testid="promoImageHeadingButtonsContainerStyle">
                        {!!path && (
                          <Link
                            path={path}
                            clickEventValues={{
                              category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
                              name: TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
                              label: TEALIUM_EVENT_LABEL.HEADER_PROMO,
                              defaultValues: metadata
                            }}
                          >
                            <img src={ICONS.PLAY_FILL} alt="Play button" />
                            {ctaLabel}
                          </Link>
                        )}
                        {seriesGuid && (
                          <Bookmark
                            programGuid={seriesGuid}
                            clickEventValues={{
                              category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
                              label: TEALIUM_EVENT_LABEL.HEADER_PROMO,
                              name: '',
                              defaultValues: metadata
                            }}
                          />
                        )}
                      </FormatHeadingHomePromoImageButtonsStyle>
                    </PromoImageHeadingContentStyle>
                  </GridElement>
                </Grid>
              </FormatHeadingPromoImageHeadingStyle>
            )}
          </>
        );
      }}
    </ApolloConsumer>
  );
};

export default FormatHeading;
